import _createForOfIteratorHelper from "E:/DUMENGZHAO/Work/blog-web/node_modules/@babel/runtime/helpers/esm/createForOfIteratorHelper.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.replace.js";
import AdaptiveLayout02 from "../components/AdaptiveLayout02";
import SideBar01 from "../components/SideBar01";
import CryptoJS from "../tools/CryptoJS";
import Prism from "prismjs/components/prism-core";
import "prismjs/components/prism-clike";
import "prismjs/components/prism-markup";
import "prismjs/components/prism-javascript";
import "prismjs/components/prism-css";
import "prismjs/components/prism-java";
import "prismjs/components/prism-sql";
import "prismjs/components/prism-json";
import "prismjs/plugins/line-numbers/prism-line-numbers.css";
import "prismjs/plugins/line-numbers/prism-line-numbers";
import "prismjs/plugins/toolbar/prism-toolbar.css";
import "prismjs/plugins/toolbar/prism-toolbar";
import "prismjs/plugins/copy-to-clipboard/prism-copy-to-clipboard";
import "prismjs/themes/prism-okaidia.css";
export default {
  data: function data() {
    return {
      sideBarType: 'detail',
      showSkeleton: true,
      url: '',
      id: '',
      detail: {},
      sideBarArticleInfo: {}
    };
  },
  components: {
    AdaptiveLayout02: AdaptiveLayout02,
    SideBar01: SideBar01
  },
  methods: {
    getDetail: function getDetail() {
      var that = this;
      that.detail = {};
      that.showSkeleton = true;
      var params = {
        id: that.id
      };
      this.get(that.url, params).then(function (res) {
        var data = res.data;
        that.detail = data;
        that.showSkeleton = false;
        that.sideBarArticleInfo = {
          id: data.id,
          title: data.title
        };
        console.log(res);
        that.$nextTick(function () {
          // dom元素更新后执行
          that.adaptImage();
          Prism.highlightAll();
        });
        var meta = document.createElement('meta');
        meta.content = data.title;
        meta.property = 'og:title';
        document.getElementsByTagName('head')[0].appendChild(meta);
      }).catch(function (error) {
        console.log(error);
      });
    },
    parseRoute: function parseRoute(route) {
      var key = route.query.key;

      try {
        var query = CryptoJS.decryptByDES(key);
        var json = JSON.parse(query);
        this.id = json.id;
        this.url = json.url;
      } catch (e) {
        console.error(e);
      }
    },
    adaptImage: function adaptImage() {
      var imgs = document.getElementById("content_container").getElementsByTagName("img");

      var _iterator = _createForOfIteratorHelper(imgs),
          _step;

      try {
        for (_iterator.s(); !(_step = _iterator.n()).done;) {
          var elem = _step.value;
          var width = elem.getAttribute("width");
          elem.removeAttribute("width");
          elem.removeAttribute("height");
          var style = elem.style;
          style.width = '100%';
          style.maxWidth = width + 'px'; //获取img的地址

          var url = elem.getAttribute("src");
          url = url.replace("https://image.dumengzhao.cn", ""); //给img重新设置地址

          elem.setAttribute("src", url);
        }
      } catch (err) {
        _iterator.e(err);
      } finally {
        _iterator.f();
      }
    }
  },
  mounted: function mounted() {},
  created: function created() {
    this.parseRoute(this.$route);
    this.getDetail();
  },
  watch: {
    $route: function $route(to) {
      //如果是离开当前路由不执行后续方法
      if (to.fullPath.indexOf("/detail") < 0) {
        return;
      }

      var oldId = this.id;
      this.parseRoute(to);

      if (oldId !== this.id) {
        this.parseRoute(this.$route);
        this.getDetail(); //重新加载数据
      }
    }
  }
};